// components/atoms/VideoGuidePopup.js
import { Box, Button, Checkbox, IconButton, Modal, Typography } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { LIST_PAGE_SHOW_VIDEO, PAGE_NAME } from 'constants/data';
import { PATH_GUILE_HOW_TO_ORDER, PATH_GUILE_HOW_TO_REGISTER } from 'constants/Paths';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

export const handleCheckStorageExist = (key) => {
  const storage = localStorage.getItem(key);
  if (storage) {
    return JSON.parse(storage);
  }
  return false;
}

export const handleUpdateVideoGuide = (pageName, newVideo) => {
  const storage = handleCheckStorageExist('videoGuide');
  if (storage) {
    const newStorage = storage.map((item) => {
      if (item.pageName === pageName
        && item.customerID === newVideo?.customerID
        && item.isAuthenticated === newVideo?.isAuthenticated
      ) {
        return newVideo;
      }
      return item;
    });
    localStorage.setItem('videoGuide', JSON.stringify(newStorage));
  }
  return null;
}

const setTimoutByPage = (pageName) => {
  switch (pageName) {
    case PAGE_NAME.HOME:
      return 0 * 1000; // 0 second
    case PAGE_NAME.GUEST:
      return 1.5 * 1000; // 1.5 second
    default:
      return 0 * 1000; // 0 second
  }
}

const VideoGuidePopup = ({ isAuthenticated, user, pageName }) => {
  const { t } = useTranslation();
  const [video, setVideo] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const defaultValue = useMemo(() => ({
    isAuthenticated,
    isShow: false,
    autoDisplay: true,
    pageName,
    customerID: user?.customerID || '',
    timeRePlay: null,
    iframeTitle: '',
    iframeUrl: '',
  }), [user, isAuthenticated, pageName]);

  const onClose = useCallback(() => {
    const currentDate = new Date();

    const timeRePlay = 30 * 24 * 60 * 60 * 1000; // 30 days
    const futureDate = currentDate.getTime() + timeRePlay;

    const newVideo = { ...video, isShow: false, timeRePlay: futureDate, autoDisplay: !isCheck };

    handleUpdateVideoGuide(pageName, newVideo);

    setVideo(newVideo);
  }, [video, pageName, isCheck]);

  const handleGetContentByPage = useCallback(() => {
    switch (pageName) {
      case PAGE_NAME.HOME:
        return {
          title: 'How to Order',
          src: PATH_GUILE_HOW_TO_ORDER
        }
      case PAGE_NAME.GUEST:
        return {
          title: 'How to Register',
          src: PATH_GUILE_HOW_TO_REGISTER
        }
      default:
        return {
          title: 'How to Order',
          src: PATH_GUILE_HOW_TO_ORDER
        }
    }
  }, [pageName]);

  const handleCheckBox = (e) => setIsCheck(e.target.checked);

  const handleCheckShowVideo = ({ isAutoCheck = true }) => {
    if (!LIST_PAGE_SHOW_VIDEO.includes(pageName)) {
      return;
    }

    const storage = handleCheckStorageExist('videoGuide') || [];
    const contentByPage = handleGetContentByPage();
    const videoSelected = storage.find((item) =>
      item.pageName === pageName
      && (
        (item.isAuthenticated === isAuthenticated && item.customerID === user?.customerID) // check customer
        || (item.isAuthenticated === false && item.customerID === '') // check guest
      )
    );

    if (videoSelected) {

      if (!videoSelected.autoDisplay && isAutoCheck) {
        return;
      }

      if (!isAutoCheck) {
        videoSelected.timeRePlay = null;
      }

      let canShow = true;
      if (videoSelected.timeRePlay) {
        canShow = new Date(videoSelected.timeRePlay) <= new Date();
      }
      if (canShow) {
        videoSelected.isShow = true;
        videoSelected.iframeTitle = contentByPage.title;
        videoSelected.iframeUrl = contentByPage.src;
        setVideo(videoSelected);
      }
    } else {
      const newVideo = { ...defaultValue, isShow: true };
      newVideo.iframeTitle = contentByPage.title;
      newVideo.iframeUrl = contentByPage.src;
      storage.push(newVideo);
      localStorage.setItem('videoGuide', JSON.stringify(storage));
      setVideo(newVideo)
    }
  }

  useEffect(() => {
    window.addEventListener('showVideoGuide', () => handleCheckShowVideo({ isAutoCheck: false }), false);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('showVideoGuide', () => handleCheckShowVideo({ isAutoCheck: false }), false);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimoutByPage(pageName);
    setTimeout(() => {
      handleCheckShowVideo({ isAutoCheck: true });
    }, timeout);
  }, []);

  if (!video) return <></>;

  return (
    <Modal open={video.isShow} onClose={onClose}>
      <Box display="flex" alignItems="center" justifyContent="center" width='100%' height='100%'>
        <Box className={styles.videoContainer}>
          <Box className={styles.videoTitle}>
            {t('popup-action:video-guide.title')}
            <IconButton onClick={onClose} className={styles.closeButton}>
              <CloseOutlined width={20} height={20} />
            </IconButton>
          </Box>
          <Box className={styles.videoWrapper}>
            <iframe title={video.iframeTitle} src={video.iframeUrl} frameborder="0" allowfullscreen />
          </Box>
          <Box className={styles.actionWrapper}>
            <Box className={styles.checkBox}>
              <Checkbox onChange={handleCheckBox} color="neutral" checked={isCheck} />
              <Typography>{t('popup-action:video-guide.do_not_show_again')}</Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={onClose} className={styles.confirmButton}>
              {t('popup-action:video-guide.understood')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
};

export default VideoGuidePopup;
